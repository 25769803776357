import Head from 'next/head';

const Versioning = () => {
  return (
    <Head>
      <meta name="version" content="1.1.2.9240" />
      <meta name="build-date" content="2024-09-16T18:15:09Z" />
    </Head>
  );
};

export default Versioning;
